import React from "react";
import global from "./assets/global.png";
import dot from "./assets/dot.png";
import msg from './assets/message (1).png'
import tp from './assets/call (1).png'
import fax from './assets/fax.png'
import location from './assets/location.png'
import circle from './assets/circle.png'
import fasbook from './assets/facebook.png'
import twiter from './assets/twitter.png'
import insta from './assets/instagram.png'

const Footer = () => {
  return (
    <footer className="pt-32 f-secs bg-[#231010]">
      <div className=" px-4 more-size 2xl:px-52 xl:w-[90%] xl:mx-auto lg:w-[90%] lg:mx-auto">
        <div className="grid clmms xl:grid-cols-12 xl:text-lg lg:text-xl text-[#C2C2C2]  ">
          <div className="xl:col-span-4  lg:col-span-12  2xl:col-span-5">
            <img src={global} alt="img" />
            <p className=" py-8 text-[16px] w-[65%]">
              At Global Financial Corp, we believe people should be at the
              center of every decision. Whether that's offering a second chance
              to hard-working families or taking the time to problem-solve with
              our dealer partners, our philosophy is you come first.
            </p>
          </div>

          <div className="xl:col-span-3 lg:col-span-6 2xl:col-span-2 2xl:-ml-14 ">
            <div className="flex flex-col ">
              <h4 className=" text-lg font-bold 2xl:pt-6 xl:pt-6  lg:pt-6">
                Business Hours
              </h4>

              <div className="flex flex-row items-baseline   ">
                <img className="w-3 pt-2 h-5 -mt-2 2xl:w-3 sm:w-3 md:w-3 sm:mt-4 2xl:mt-5 md:mt-4 xl:-mt-2" src={dot} alt="img" />
                <p className=" 2xl:pt-8 xl:text-[15px] xl:pt-2 ml-3 2xl:w-[70%]">
                  Mon-Fri: 9am-6pm EST,<br/> Saturday 10am-2pm EST
                </p>
              </div>
            </div>
          </div>
          <div className="xl:col-span-2 lg:col-span-3 2xl:col-span-2 2xl:-ml-5  text-[#C2C2C2]">
            <div className="flex flex-col">
              <h4 className=" text-lg font-bold 2xl:pb-6 pt-6">Useful Links</h4>
              <div className="flex flex-row items-center pb-3">
                <img className="w-3 pt-2 mr-4" src={dot} alt="img" />
                <a className="mt-2 xl:text-[15px]" href="#">Contact Us</a>
              </div>
              <div className="flex flex-row items-center pb-4">
                <img className="w-3  mr-4" src={dot} alt="img" />
                <a className=" xl:text-[15px]" href="#">Login</a>
              </div>
              <div className="flex flex-row items-center pb-4">
                <img className="w-3  mr-4" src={dot} alt="img" />
                <a className=" xl:text-[15px]"  href="#">Privacy Policy</a>
              </div>
              <div className="flex flex-row items-center">
                <img className="w-3  mr-4" src={dot} alt="img" />
                <a className=" xl:text-[15px]"  href="#">Terms & Conditions</a>
              </div>
            </div>
          </div>
          <div className="xl:col-span-3 cn-infos lg:col-span-3 2xl:col-span-3 2xl:ml-16 2xl:pb-3   text-[#C2C2C2]">
            <div className="flex flex-col cnf ">
              <h4 className=" text-lg font-bold 2xl:pb-6 pt-6">Contact Info</h4>
              <div className="flex flex-row items-center pb-2">
                <img className="w-5 pt-2 mr-4" src={msg} alt="img" />
                <a className=" xl:text-[15px]"  href="mailto:custsvc@gfcfinance.com">custsvc@gfcfinance.com</a>
              </div>
              <div className="flex flex-row items-center pb-2 lg:pt-1">
                <img className="w-5 pt-2 mr-4" src={tp} alt="img" />
                <p className=" xl:text-[15px]"  > <a href="tel:2164583100">(216) 458-3100</a></p>
              </div>
              <div className="flex flex-row items-center pb-2 lg:pt-1">
                <img className="w-6 pt-1 mr-4  " src={fax} alt="img" />
                <p className=" xl:text-[15px]"  > <a href="tel:2163321633">(216) 332-1633 </a>  </p>
              </div>
         
              <div className="flex flex-row items-center mt-2">
                <img className="w-6  mr-4 relative 2xl:-mt-14 xl:-mt-10
                 
                " src={location} alt="img" />
                <a className="mb-4 2xl:mb-7 xl:text-[15px] " href="#">5350 Transportation Blvd Suite 17 <br/>
                Garfield Heights, OH 44125</a>
              </div>
            </div>
            <div className="flex justify-start space-x-3 mt-3">
					<a rel="noopener noreferrer" href="https://www.facebook.com/" title="Facebook" >
                    
                        <img className="relative"  src={circle} alt="img" />
                        <img className="absolute -mt-7 ml-2" style={{marginLeft:"10px"}} src={fasbook} alt="img"/>
					</a>
					<a rel="noopener noreferrer" href="https://www.instagram.com/" title="Instagram">
                    <img className="relative" src={circle} alt="img" />
                        <img className="absolute -mt-7 ml-3" src={insta}    alt="img"/>
					</a>
					<a rel="noopener noreferrer" href="https://twitter.com/" title="Twitter">
                    <img className="relative" src={circle} alt="img" />
                        <img className="absolute -mt-8 ml-2" src={twiter} alt="img"/>
					</a>
				</div>
          </div>

        </div>
      </div>
      <hr class="my-6 mx-16 border-slate-800 opacity-50  dark:border-gray-800 lg:mt-10" />
      <div className=" -mt-3 pb-3  text-center tracking-wide  text-[#C2C2C2] sm:text-sm">© 2023, Global Financial  Corp. |  All Rights Reserved</div>
    </footer>
  );
};


export default Footer;





