import React from "react";
import "./home/middle.css";
import user from "./assets/user.png";
import UserSvg from "./assets/user";
import call from "./assets/calll.png";
import message from "./assets/messagee.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faComments } from "@fortawesome/free-regular-svg-icons";
// import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const Middle = () => {
  return (
    <>
      <section id=" m-sec" className="second-sec">
        <div className="container mx-auto">
          <div className="text-center">
            <h1
              className=" m-heading m-heading_sm m-heading_md m-heading_lg m-heading_xl m-heading_2xl "
              id="headerr"
            >
              It’s Easier Than Ever To Manage Your Account
            </h1>
            <div className="">
              <p
                id="home-pera"
                className="m-pera m-pera_sm m-pera_md m-peraa_lg m-pera_xl m-pera_2xl"
              >
                Utilize our intuitive online tools or rely on our dedicated
                customer service team to help manage your account and pay your
                bill.
              </p>

              <div className="m-boxess m-sm_boxes m-lg_boxes m-2xl_boxes ">
                <div id="box" className="custom_box m-boxes m-boxes_sm m-box_md m-box_lg m-box_lg-trans m-box_xl m-box_2xl">
                  <img src={user} className="m-icon m-icon_md m-icon_lg m-icons_xl m-icons_2xl" />
                 <div id="svg">
                 {/* <UserSvg /> */}
                 </div>
                  {/* <FontAwesomeIcon  icon={faUser} className="icon icon_lg" /> */}
                  <h2 className="m-box_heading m-box_heading_sm m-box_heading_md m-box_heading_lg m-box_heading_xl m-box_heading_2xl">
                    <b>Customer Portal</b>
                  </h2>
                  <p
                    id="home-pera"
                    className="m-pera_box m-pera-box_sm m-pera-box_md m_pera_box_lg m_pera_box_xl m-box-pera_2xl"
                  >
                    Our online tool will allow you to pay your bill, calculate a
                    payoff, setup recurring payments, and more!
                  </p>
                </div>
                <div className=" m-boxes m-boxes_sm m-box_md m-box_lg m-box_lg-trans m-box_xl m-box_2xl">
                  <img src={call} className= " m-icon m-icon_md m-icon_lg m-icons_xl m-icons_2xl" id="icon" />
                  {/* <FontAwesomeIcon
                    icon={faPhoneVolume} id="ico"
                    className="icon icon_lg"
                  /> */}
                  <h2 className="m-box_heading m-box_heading_sm m-box_heading_md m-box_heading_lg m-box_heading_xl m-box_heading_2xl">
                    <b>Call An Agent</b>
                  </h2>
                  <p
                    id="home-pera"
                    className="m-pera_box m-pera-box_sm m-pera-box_md  m_pera_box_lg m_pera_box_xl m-box-pera_2xl"
                  >
                    Our staff is standing by to help you with anything related
                    to your account. Call us at <a href="tel:2164583100"> (216) 458-3100.</a>
                  </p>
                </div>
                <div className="m-boxes m-boxes_sm m-box_md m-box_lg m-box_lg-trans m-box_xl m-box_2xl ">
                  <img
                    src={message}
                    className="m-icon m-icon_md m-icon_lg m-icons_xl m-icons_2xl "
                    
                  />
                  {/* <FontAwesomeIcon icon={faComments} className="icon icon_lg" /> */}
                  <h2 className="m-box_heading m-box_heading_sm m-box_heading_md m-box_heading_lg m-box_heading_xl m-box_heading_2xl">
                    <b>Frequently Asked Questions</b>
                  </h2>
                  <p
                    id="home-pera"
                    className="m-pera_box m-pera-box_sm m-pera-box_md  m_pera_box_lg m_pera_box_xl m-box-pera_2xl"
                  >
                    Get answers to questions regarding payment methods,
                    insurance, payoffs, titles, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Middle;
