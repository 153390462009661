import React from "react";
import logo from "./assets/image1.png";
import poster from "./assets/Vector.png";
import corner from "./assets/Ellipse 1.png";
import victor from "./assets/Vector 2.png";
import "./home/home.css";
import Middle from "./middle";
import Thirdp from "./thirdp";
import Footer from "./footer";
import MyModal from "./mymodal";
import spring from "./assets/spring.png";
import vv from "./assets/vv.png";
import { Link } from "react-router-dom";

const Homepage = () => {
  return (
    <>
      <section className="firsts z-10 bg-[#FAF2F2] w-md" id="sec_tion">
        {/* <img
          src={corner}
          className=" corner  corner_lg corner_xl  corner_2xl "
        /> */}
        <div className="container mx-auto ">
          <nav className=" nav nav_sm nav_md  navv_lg">
            <img src={logo} alt="logo_image" className=" logo_lg logo_xl logo_2xl" />
            <ul className=" navv nav_lg nav_xl nav_2xl">
              <li> Home </li>
              <li>  About Us  </li>
              <li>Dealers</li>
              <li>Customers</li>
              <li>Contact Us</li>
              <li className="btn-menu"><Link target="_blank" to="https://dealer.gfcfinance.com/dealerportal/login/login.aspx"> DEALER PORTAL </Link> </li>
            </ul>

                </nav>

        <div className="mob-teb">                  
          <div className="toggle toggle_sm toggle_md toggle_lg toggle_xl toggle_2xl">
        
            {/* <div className="header"> </div> */}
            <input className="hidden" type="checkbox" id="openSidebarMenu" />
            <label
              for="openSidebarMenu"
              className="sidebarIconToggle"
              id="togglemenu"
            >
              <div className="spinner top"></div>
              <div className="spinner middle"></div>
              <div className="spinner bottom"></div>
            </label>
            <nav id="sidebarMenu">
              <ul className="w-44">
                {/* ============================================================ */}

                <li className=" p-6 text-[130%]">Home</li>
                <hr />

                <li className=" p-6 text-[130%]">About US</li>
                <hr />

                <li className=" p-6 text-[130%]">Dealers</li>
                <hr />
                <li className=" p-6 text-[130%]">Customers</li>
                <hr />
                <li className=" p-6 text-[130%]">Contact Us</li>

                  <li className="p-6 text-[130%]"><Link target="_blank" to="https://dealer.gfcfinance.com/dealerportal/login/login.aspx"> Dealer Portal </Link> </li>
                            
        
                
              </ul>
            </nav>
          </div>

          <img src={logo} alt="logo_image" className=" logo_lg logo_xl logo_2xl" />


          </div>



          {/* ============================Toggle End========================== */}

          <div className="box_ box_sm boxx_lg fs-sec boxx_xl boxx_2xl">
            <div className="box_lg box_xl">
              <h1
                className=" heading heading_sm heading_md heading_lg heading_xl heading_2xl"
                id="font"
              >
                {" "}
                Common Sense Auto Lending
              </h1>
              <p
                className=" pera pera_sm pera_md pera_lg pera_xl pera_2xl "
                id="home-pera"
              >
                Global Financial Customers can access account information
                anytime via the Customer Portal page. If you wish to speak to a
                representative, please call <a href="tel:2164583100">(216) 458-3100</a>.
              </p>
              <div className="btn-2 btn-2_md btn-2_sm btn_2_ btn-2_lg btn-2_xl btn-2_2xl">
                {/* <button className=" btn  btn-2_md btn-2_lg btn-lgs btn_2 btn_2_xl btn_2_2xl"> */}
                  
                <Link target="_blank" className="btn  btn-2_md btn-2_lg btn-lgs btn_2 btn_2_xl btn_2_2xl" to="https://www.myfexaccount.com/clients/_master/myfexaccount_default.aspx">
                   CUSTOMER PORTAL
                   </Link>
                {/* </button> */}
                <br />

                <Link target="_blank" className=" btn btn_bdr btn-2_md btn-2_lg btn_2 btn_2_xl btn_2_2xl" to="https://www.myfexaccount.com/clients/_master/myfexaccount_default.aspx">
                  {/* <button className=" btn btn-2_md btn_2 btn_2_xl btn_2_2xl" > */}
                    MAKE A PAYMENT
                  {/* </button> */}
                </Link>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src={poster}
                alt="poster_image"
                className="poster poster_sm poster_lg poster_xl poster_2xl"
              />
            </div>
          </div>
        </div>
        {/* <img src={vv} className="absolute w-[26%] h-[40%] ml-[61%] -mt-[9.5%] 2xl:aspect-[2px]" /> */}
        <img
          src={spring}
          className="absolute  2xl:w-[10.5%] ml-[73%] mt-[5.5%] lg:w-[11%] md:w-[12%] md:ml-[76%]  lg:ml-[75%] xl:w-[10.5%] xl:ml-[72.5%] 2xl:ml-[70.5%] "
        />
        <img src={victor} className="pt-8 2xl:pb-0  -mb-5  waves-ani " />
      </section>
      <MyModal />
      <Middle />
  
      <Thirdp />
      <Footer />
    </>
  );
};

export default Homepage;
