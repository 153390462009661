import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Homepage from './component/homepage'
import Sidenav from './component/sidenav'

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage/>}/>
          <Route path='/side' element={<Sidenav/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
