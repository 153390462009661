import React from "react";
import logo from "./assets/image 1.png";
import credit from "./assets/creditcrd.png";
import tick from "./assets/tick.png";
import spring from "./assets/spring.png";

const Homepage = () => {
  return (
    <>
      <section className="gl-fin">
        <div className="container fast-msx mx-auto mt-44 w-[87.5%] ">
          {/* <div className="flex justify-center items-center "> */}
            <div className="flex flex-col md:flex-col xl:w-[85%] 2xl:w-[80%] lg:w-[94%] lg:flex-row  justify-center  w-[85%] mx-auto ">
              <div className="colm-l w-[50%]  2xl:mr-[10px]  top-img">
                <img
                  className="md:w-full lg:w-11/12 2xl:w-[87%] "
                  src={credit}
                  alt="creditcard_img"
                />
                <img
                  className=" md:w-40 lg:w-[30%]  mt-2 dks"
                  src={spring}
                  alt="spring_img"
                />
              </div>
              <div className="colm-r w-[50%] md:w-[50%] lg:mt-2 md:mt-7 sm:text-xl md:text-3xl 2xl:mt-4 2xl:  ">
                <h1
                  className="font-bold lg:text-[32px] xl:text-[35px] lg:pt-0 text-2xl 2xl:mb-9  sm:text-3xl md:text-3xl xl:text-5xl 2xl:text-[50px] lg:text-4xl mb-[3%] -mt-2  "
                  // style={{fontSize:"40px",fontWeight:"700",lineHeight:"60px"}}
                >
                  Fast and Easy Payments
                </h1>
                <p className="pay-fr sm:w-11/12  2xl:text-[19px] 2xl:w-[105%] lg:text-base text-md xl:text-[14px] 2xl:mb-3 md:text-lg  mb-1">
                  Paying your Global Financial bill on time is one of the biggest
                  steps you can take to improve your credit.
                </p>
                <br></br>

                <div className="grid grid-cols-2 gap-7 grid-s sm:-mt-3  lg:text-base items-start">
                  <div className="flex items-center  2xl:mt-4 p-">
                    <img className="w-[5%] mr-4" src={tick} alt="img" />
                    <p className="xl:text-[14px] sm:text-base text-sm ">
                      One-Time Online
                    </p>
                  </div>
                  <div className="flex items-center  2xl:mt-4 p-">
                    <img
                      className="w-[5%] mr-4 2xl:ml-[70px]"
                      src={tick}
                      alt="img"
                    />
                    <p className="xl:text-[14px] sm:text-base text-sm">By Mail</p>
                  </div>
                  <div className="flex items-center  2xl:mt-2 p-">
                    <img className="w-[5%] mr-4" src={tick} alt="img" />
                    <p className="xl:text-[14px] sm:text-base text-sm">
                      Recurring Payments
                    </p>
                  </div>
                  <div className="flex items-center  2xl:mt-2 ">
                    <img
                      className="w-[5%] mr-4 2xl:ml-[70px]"
                      src={tick}
                      alt="img"
                    />
                    <p className="xl:text-[14px] sm:text-base text-sm">By Phone</p>
                  </div>
                  <div className="flex items-center  2xl:mt-1 ">
                    <img className="w-[5%] mr-4" src={tick} alt="img" />
                    <p className="xl:text-[14px] sm:text-base text-sm">In Person</p>
                  </div>
                  <div className="flex items-center  2xl:mt-1 ">
                    <img
                      className="w-[5%] mr-4 2xl:ml-[70px]"
                      src={tick}
                      alt="img"
                    />
                    <p className="xl:text-[14px] sm:text-base text-sm">
                      Personal Banking
                    </p>
                  </div>
                </div>

                <button
                  className="btn-lgs w-[37%] 2xl:text-[17px] xl:mt-24 xl:text-[13px] lg:mt-12 lg:text-[12px] lg:h-8 2xl:h-12 rounded border-2 mt-20 border-[#7C0000] focus:bg-[#7C0000] hover:bg-[#7C0000] hover:text-white focus:text-white tracking-tighter text-sm "
                  type="submit"
                >
                   <a target="_blank" href="https://www.myfexaccount.com/clients/_master/myfexaccount_default.aspx">
                  VISIT PAYMENTS CENTER
                  </a> 
                </button>

                <img
                  className=" md:w-40 lg:w-[30%]  mt-2 ph-responsive"
                  src={spring}
                  alt="spring_img"
                />

              </div>
            </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Homepage;
