import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import pic from "./assets/pic.png";
import down from "./assets/downarow.png";
import down1 from './assets/down-arrow.png';

export default function MyModal() {
  let [thankModal, setThankModal] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [firstName, setFirstName] = useState("");
  let [email, setEmail] = useState("");
  let [question, setQuestion] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalA() {
    setThankModal(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function handleSendRequest(e) {
    e.preventDefault();
    setThankModal(true);
    console.log("Name:", firstName);
    console.log("Email:", email);
    console.log("Question:", question);
    setFirstName("");
    setEmail("");
    setQuestion("");
    // closeModal();
    setTimeout(() => {
      closeModalA();
    }, 2000);
  }

  return (
    <>
      <div className="inset-0 chatbot overflow-y-auto">
        <div className="p-4 text-center">
          <button
            type="button"
            onClick={openModal}
            className="relative flex items-center rounded-sm shadow px-4 py-2 text-sm font-medium bg-white text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            <img src={pic} alt="pic" className="w-8 h-8 mr-2" />
            Hey, we’re here to help!
          </button>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg bg-[#7C0000] py-3 text-white pl-3 flex items-center justify-between"
                  >
                    <span>Chat with us!</span>
                    <button
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                      className="mr-2 w-6 pl-1 border-[1px] text-white h-6 rounded shadow-xl border-slate-400 focus:outline-none hover:bg-[#7C0000]"
                    >
                      <img src={down1} alt="image" className="mix-blend-screen pr-1" />
                    </button>
                  </Dialog.Title>
                  <div className="border-solid border-2 m-2  flex flex-col justify-center items-center rounded-md shadow">
                    <div className="mt-2 mb-2">
                      <p className="text-xl text-gray-500" id="c_hi">
                        Hi! Enter your info anytime right here.
                      </p>
                    </div>
                    <form
                      className="flex flex-col justify-center items-center"
                      onSubmit={handleSendRequest}
                    >
                      <input
                        className="border-solid border-2 w-80 mb-3 rounded-md"
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder=" First Name*"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <input
                        className="border-solid border-2 w-80 mb-3 rounded-md"
                        type="email"
                        id="email"
                        name="email"
                        placeholder=" Email*"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <textarea
                        className="border-solid border-2 mb-3 w-80 rounded-md"
                        placeholder=" Enter your Question*"
                        name="question"
                        id="question"
                        required
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                      ></textarea>
                      <div className="m-2 ">
                        <input
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-[#7C0000] px-4 py-2 text-sm font-medium text-white hover:bg-[#7C0000] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-700 focus-visible:ring-offset-2"
                          value={`Send Chat Request`}
                        />
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={thankModal} as={Fragment}>
        <Dialog as="div" className="relative" onClose={closeModalA}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="inset-0 overflow-y-auto">
            <div className=" p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl bg-[#7C0000] text-white pl-3 flex items-center justify-center h-80"
                  >
                    <h3>Thank You</h3>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}